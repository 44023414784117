<template>
  <div class="container py-5">
    <p class="fw-bold">匯成醫美創新運營大會</p>
    
    <p>時間：2023 07/01-03</p>
    <p>地點：南京 揚子江國際會議中心</p>
    <p>展位：V14 V15</p>
    <p>
     《匯成醫美®創新運營大會》是由匯成醫美®教育主辦，旨在推動醫美行業運營創新的平台，解決當下醫美機構因運營創新力不足，導致的增長緩慢、增長停滯、生存危機等一系列問題，於每年7月1-3日定期舉辦。本次會議覆蓋醫美產業100多萬從業人員，33%直客醫美、33%渠道醫美、33%雙美機構。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_26.jpg"
    />

  </div>
</template>
<script>
export default {};
</script>
